import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FourDService } from '4d-mapper';
import { AppService } from '../../app.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrls: ['./app-dialog.component.scss']
})
export class AppDialog implements OnDestroy {

  inputControl = new FormControl('');
  meta: any
  passedValue: any;


  constructor(public dialogRef: MatDialogRef<AppDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private fourD: FourDService, public service: AppService, @Inject(LOCALE_ID) private locale: string, public dialog: MatDialog) {
    if (this.data.isExternalURL) {
      this.inputControl.setValidators([Validators.minLength(1), Validators.maxLength(300)
      ]);
    } else {
      this.inputControl.setValidators([Validators.required, Validators.minLength(2)]);
    }
    this.meta = data.meta || {}
    if (data.input && data.input.value) {
      this.inputControl.setValue(data.input.value);
      this.passedValue = data.input.value;
    }

  }

  onClick(res): void {
    this.dialogRef.close(res)
  }

  ngOnDestroy(): void {

  }
}
