import { Injectable } from '@angular/core';
import { FileState } from '../constants';

export class FdmFile {
  projectId:number = null;
  filename:string = null;
  datasetRef:number = null;
  size:number = null;
  state:string = FileState.NOT_READY;
  progress:number = null;

  constructor( projectId:number, filename:string ) {
    this.projectId = projectId;
    this.filename = filename;
    this.progress = 0;
    this.size = 0;
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilesHelperService {

  private files:Map<string,FdmFile> = new Map();
  public jobProject:any = {};
  public jobBatchProject:any = {};

  public matrix:any = {};

  private neededFiles
  constructor() { }

  idx ( projectId:number, filename:string = null ) {
    return projectId + '-' + filename;
  }

  add ( projectId:number, filename:string = null ) {
    let idx = this.idx ( projectId, filename );
    let values = '   ' + 'no_state' + ' - ' + 'no_size' + '   ';
    this.files.set ( idx, new FdmFile ( projectId, filename ) );
    this.matrix[idx] = values;
  }

  getState ( projectId:number, filename:string = null ) {
    let idx = this.idx ( projectId, filename );
    let item = this.files.get ( idx ) ;
    if ( item ) {
      return item.state;
    }
    return null;
  }

  update ( projectId:number, filename:string = null, state:string = null, size:number = null ) {
    if ( state == null ) {
      return;
    }
    let values = '   ' + ( state ? state : 'no_state' ) + ' - ' + ( size != null ? size : 'no_size' ) + '   ';
    let idx = this.idx ( projectId, filename );
    let item = this.files.get ( idx ) ;
    if ( item ) {
      item.state = state;
      if ( size ) { item.size = size; }
    } else {
      this.add ( projectId, filename );
      let i = this.files.get(idx);
      i.state = state;
      if ( size ) { i.size = size; }
    }
    this.matrix[idx] = values;
  }

  get() {
    return this.files.values();
  }
  
}

