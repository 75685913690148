import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppRoutes} from './commons/constants';
import {P404Component} from './view/error/p404/p404.component';
import {DashboardComponent} from './containers/dashboard/dashboard.component';

// main parent router
const routes: Routes = [
  {path: '', redirectTo: AppRoutes.MAIN, pathMatch: 'full'},

  {path: 'v2', redirectTo: 'v2', pathMatch: 'full'},

  {path: AppRoutes.AUTH, loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    component: DashboardComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: AppRoutes.MAIN,
        loadChildren: () => import('./view/view.module').then(m => m.ViewModule)
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
