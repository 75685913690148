import {SearchQueryNode, Vasat, VasatModel, VasatModelClass} from 'vasat';


abstract class AppBaseModel<T extends VasatModel<T>> extends VasatModel<T>{

    json:any

    toJSON() {
        var res = super.toJSON()
        delete res["permissions"]
        delete res["type"]
        return res
    }

}


export abstract class ACLModel<T extends VasatModel<T>> extends VasatModel<T>{

  canWrite:boolean
  canRead:boolean
  canDelete:boolean
  permissions:string[]

  set(a:any){
    super.set(a)
    if(this.permissions){
      this.canRead = !!this.permissions.find(p => p == 'api_read')
      this.canWrite = !!this.permissions.find(p => p == 'api_update')
      this.canDelete = !!this.permissions.find(p => p == 'api_delete')
    }
    return this
  }
  toJSON():any{
    let o = super.toJSON()
    let res = {}
    Object.keys(o).filter(k => k != 'canRead' && k != 'canWrite').forEach(k => res[k] = o[k])

    delete o.permissions
    delete o.canDelete
    delete o.canRead
    delete o.canWrite

    return res
  }

}


export class ObjectMeta extends ACLModel<ObjectMeta>{
  uid:string
  qMap:any[]
  level:string
  strip:any
  seq?:number
  account_acl:{[sqn:string] : string[]} = {}
  acl:string[] = []

  columns:string[]

  isSite:boolean

  cascades:{
    field:string,
     type:string,
     derived?:any
  }[]

  filters:{
    name:string,
    query:SearchQueryNode,
    showSubs:boolean,
    columns?:string[]
  }[]

  derived:any
  baseFilter:SearchQueryNode

  stubClazz:VasatModelClass<any>

  constructor(private V:Vasat,r?:any){
    super(V,'ObjectMeta',r)

  }

  set(a:any){
    super.set(a)
    var self = this

    var reg:VasatModelClass<any> = this.V.registeredClassForString(self.uid)

    if(!reg){
        var self = this
        reg = class StubImpl extends VasatModel<StubImpl>{
                  constructor(){
                    super(self.V,self.uid)
                  }
                  className(){
                      return self.uid
                  }
            }
        this.V.registerClass(reg)
    }

    this.stubClazz = reg
    return this
  }
  toJSON():any{
      let o = super.toJSON()
      delete o.account_acl
      delete o.acl_createSqns
      delete o.acl_searchSqns
      delete o.acl
      delete o.selected
      delete o.canDelete
      delete o.permissions

      delete o.type
      return o
  }



}
