import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FourDService} from '4d-mapper';
import {Vasat, VasatConfig} from 'vasat';
import {environment} from '../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './commons/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FourDInjector} from './commons/FourDInjector';
import {MatIconModule, MatIconRegistry, MatExpansionModule} from '@angular/material';
import {SnotifyModule, SnotifyService} from 'ng-snotify';
import {SnotifyConfig} from './libs/snotify.config';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {DashboardComponent} from './containers/dashboard/dashboard.component';
import {P404Component} from './view/error/p404/p404.component';
import {NgxStripeModule} from 'ngx-stripe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {VasatService} from 'vasat-ng';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { BillingSetupComponent } from './view/dialog/billing-setup/billing-setup.component';
import { ApiComponent } from './view/dialog/api/api.component';

var vasat =   new Vasat(
  new VasatConfig(
    environment.production ? location.protocol + '//' + location.host + '/vasat' :
      // extra to now have isntance deployed on sandpit to point to live server by default.
      location.host == 'sandpit.4dmapper.com' ? 'https://beta.4dmapper.com/vasat' :

        // set this to any host for dev
       'https://app.makeitaccurate.com/vasat'
        // 'https://app.4dmapper.com'
      //   'https://sandpit.4dmapper.com/vasat'
    //  'http://localhost:9000'
//'https://cemeteries.ruapehudc.govt.nz/vasat'

// 'https://4dlink.geolinkjapan.com/vasat'
    ,
    localStorage['last4d_token'] || '4dmapper',
    localStorage['last4d_secret'] || '')
  ,null)


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    P404Component,
    BillingSetupComponent,
    ApiComponent
  ],
  imports: [
    SharedModule,
    
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    //for i18
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    SnotifyModule,
    TabsModule.forRoot(),
    NgxStripeModule.forRoot(),
    FontAwesomeModule,
    MatIconModule
  ],
  entryComponents: [
    BillingSetupComponent,
    ApiComponent
  ],
  providers: [
    {
      provide: FourDService,
      useValue: new FourDService(vasat)
    },
    {
      provide: Vasat,
      useValue: vasat
    }, {
      provide: VasatService,
      useValue: vasat
    },
    /*{ // Vasat Config to specify backend server url
      provide: VasatConfig,
      useValue: {
        host:
          environment.production ? location.protocol + '//' + location.host + '/vasat' :

            // set this to any host for dev
            'https://beta.4dmapper.com/vasat'
        // 'https://sandpit.4dmapper.com/vasat'
        // 'http://localhost:9000'
        //'https://cemeteries.ruapehudc.govt.nz/vasat'

        // 'https://4dlink.geolinkjapan.com/vasat'
        ,
        clientSecret: localStorage['last4d_secret'] || '',
        clientId: localStorage['last4d_token'] || '4dmapper',
        useCookies: false
      }
    }*/
    SnotifyService,
    {
      provide: 'SnotifyToastConfig',
      useValue: SnotifyConfig
    },
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }*/
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  f: FourDInjector

  constructor(public matIconRegistry: MatIconRegistry, injector: Injector) {
    matIconRegistry.registerFontClassAlias('fontawesome', 'fa')
    AppModule.injector = injector;
    this.f = new FourDInjector(injector);
  }

}

/***********************************************
 *  Translation configuration configuration
 ***********************************************/
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
