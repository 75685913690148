import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../commons/app.service';
import {BaseComponent} from '../../../commons/BaseComponent';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

import { FourDService,ProcessorAPI } from '4d-mapper';
import { mergeMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

export const APITypes = {
  ProcessorAPI: {NAME:"ProcessorAPI",IMPL:"lib.processors.Remote4DProcessor"},
  Datum360: {NAME:"Datum360",IMPL:"lib.processors.remote_apis.Datum360"},
  AutoDesk: {NAME:"AutoDesk",IMPL:"lib.workers.AutodeskWorker"},
  Trackonus: {NAME:"Trackonus",IMPL:"lib.processors.Remote4DProcessor"},
  Photoscan: {NAME:"Photoscan",IMPL:"Photoscan"},
  Agronomeye: {NAME:"Agronomeye",IMPL:"lib.processors.remote_apis.Agronomeye"},
  PositionPartners: {NAME:"PositionPartners",IMPL:"lib.processors.remote_apis.PositionPartners"},
  CapturingReality: {NAME:"CapturingReality",IMPL:"Photoscan"},
  SDK: {NAME:"SDK",IMPL:"SDK"}
}

@Component({
  selector: 'api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent extends BaseComponent implements OnInit {

  error: string
  success: string

  apiTypes:string[]       = [,  APITypes.SDK.NAME, APITypes.Datum360.NAME, APITypes.Trackonus.NAME,  APITypes.Photoscan.NAME,  APITypes.CapturingReality.NAME,  APITypes.Agronomeye.NAME]
    availApiTypes:string[]  = []
    AGRONOMEYE =  APITypes.Agronomeye.NAME

    // our secret clipboard
    clipboardText:string = '';

    sdks:ProcessorAPI[] = []
    otherApis:ProcessorAPI[] = []

    _subs:Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<ApiComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    , public fourD: FourDService, public service: AppService, public router: Router,
    public snackBar: MatSnackBar, public dialog: MatDialog, private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private fb: FormBuilder) {
    super(router, snackBar, dialog);

    this._subs.push( this.fourD.onActiveAccountReady().subscribe(s => {
      // Refresh available Apis
      this.refreshApis();
  }));
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe());
}


ngOnInit() {
    // this.refreshApis();
}

refreshApis() {
    this.availApiTypes = [APITypes.SDK.NAME]
    this.sdks = this.fourD.apis.filter(ap => ap.processorImpl == 'SDK' && ap.account.id == this.fourD.activeTeam.id)
    this.otherApis = this.fourD.apis.filter(ap => ap.processorImpl != 'SDK' && ap.account.id == this.fourD.activeTeam.id)
    console.log('#api account',this.fourD.activeTeam.id);
    console.log('#api total,sdk,other',this.fourD.activeTeam.id,this.fourD.apis,this.sdks,this.otherApis);
}

hasApiByType(type:string) {
    // If no apis then return false
    if (!this.fourD.apis) return false
    var res = false
    // Go through apis list and search for the type
    for (var i=0; i < this.fourD.apis.length; i++) {
        if (this.fourD.apis[i].type == type) {
            res = true
            break
        }
    }
    return res
}

copyToClipboard(inputElement){
    
    // get the value
    let val = inputElement.value;

    // find the clipboard
    let cb = <HTMLInputElement> document.getElementById('clipboard');

    // transfer values
    cb.value = val;
    this.clipboardText = val;

    // select the clipboard field, and make a copy
    cb.select();
    document.execCommand('copy');

    // select back to the original field
    inputElement.select();
    inputElement.setSelectionRange(0, 0);

    // assume success
    this.openSnackBarSuccess('Copied')
}

saveApi(api:ProcessorAPI) {
    api.saveObservable().pipe(mergeMap(res => {
        return this.fourD.activeTeam.enableDisableApi(api.type, true)
    })).subscribe(
            succ => this.openSnackBarSuccess("Saved")
            ,err => this.openSnackBarError("ERROR", "", err)
        )
}

deleteApi(api:ProcessorAPI, index:number) {
    this.openDialog('Are you sure?','','yes','cancel').subscribe(s => {
        if(s){
            api.destroyObservable().pipe(
                mergeMap(res=> {
                    return this.fourD.activeTeam.enableDisableApi(api.type, false)
                })).subscribe(
                    succ=> {
                        this.fourD.apis.splice(index, 1)
                        this.sdks = this.sdks.filter(s => s != api)
                        // refresh new api pull down
                        this.refreshApis()
                        this.openSnackBarSuccess("Done")
                    },
                    err=> this.openSnackBarError("ERROR", "", err)
                )
        }
    }
        
    )
    
}

addApi(apiType:string) {
    // Create the new api object
    console.log ( 'create APIType', apiType );
    let newApi = new ProcessorAPI(this.service.V).set({type:apiType, processorImpl:APITypes[apiType].IMPL })
    // Specify default name if not ProcessorAPI
    if (apiType != APITypes.ProcessorAPI.NAME) newApi.name = apiType
    if(apiType == 'Photoscan' || apiType == 'CapturingReality' || apiType == 'SDK'){
        newApi.saveObservable().subscribe(s => {
            this.fourD.apis.push(newApi)
            if(apiType == 'SDK')
                this.sdks.push(newApi)
        })
    }
    else{
        // push to the current list of apis
        this.fourD.apis.push(newApi)
    }
    // refresh new api pull down
    this.refreshApis()
}

testAPI(apiType:ProcessorAPI){
    apiType.saveObservable().subscribe(_ =>
        this.fourD.V.api('/remote/' + apiType.id + '/test').subscribe(s =>
            this.openSnackBarSuccess('Connection OK')
            ,e =>

            this.openSnackBarError('Connection ERROR '+ e)
        )
    )
}

  cancel() {
    this.dialogRef.close()
  }

  ngAfterViewInit() {

  }

}
