import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule, MatIconRegistry,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule, MatProgressBarModule,
  MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule, MatSortModule, MatStepperModule,
  MatTableModule,
  MatTabsModule, MatTooltipModule, MatToolbarModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppDialog} from '../dialog/app-dialog/app-dialog.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {QMAPModule, QMAPService} from 'ng-qmap';
import {VasatQMAPService} from '../app.service';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/pro-solid-svg-icons';
import {far} from '@fortawesome/pro-regular-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fab} from '@fortawesome/free-brands-svg-icons';
import { VasatModule } from 'vasat-ng'

import { DateAgoPipe } from '../../pipes/date-ago.pipe';

library.add(fas, far, fal, fab)


@NgModule({
  declarations: [
    AppDialog,
    DateAgoPipe
  ],
  imports: [
    QMAPModule,
    CommonModule,
    VasatModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatBadgeModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSortModule,
    CommonModule,
    MatTableModule,
    TranslateModule,
    DragDropModule,
    MatSliderModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatCardModule
  ],
  exports: [
    QMAPModule,
    CommonModule,
    VasatModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTabsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatDividerModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatBadgeModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSortModule,
    CommonModule,
    MatTableModule,
    TranslateModule,
    DragDropModule,
    MatSliderModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatToolbarModule,
    MatCardModule,
    DateAgoPipe
  ],
  entryComponents: [
    AppDialog
  ],
  providers:[
    MatIconRegistry,

    {
      provide: QMAPService,
      useClass: VasatQMAPService
    }
  ]
})
export class SharedModule { }
