import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {FourdCesiumHelper} from '4d-mapper';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  cesiumReady: Subject<any> = new Subject<any>();
  helper: FourdCesiumHelper
  viewer: any;


  constructor() { }


}
