import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AppService} from '../../commons/app.service';
import {TranslateService} from '@ngx-translate/core';
import {flatMap, mergeMap, tap} from 'rxjs/operators';
import {empty as observableEmpty} from 'rxjs';
import {BaseComponent} from '../../commons/BaseComponent';
import { Account, FourDService, Project, BillingSetup, JobBatch, Job, Dataset } from '4d-mapper';
import {AppRoutes, Constants, FileState} from '../../commons/constants';
import {Router} from '@angular/router';
import {MatDialog, MatSnackBar} from '@angular/material';
import {AppComponent} from '../../app.component';
import {NavData} from '../../_nav';
import {ProjectService} from '../../commons/project.service';
import { BillingSetupComponent } from 'src/app/view/dialog/billing-setup/billing-setup.component';
import { ApiComponent } from 'src/app/view/dialog/api/api.component';
import { FilesHelperService } from 'src/app/commons/service/files-helper.service';

/*
After login, user will be redirected to Dashboard screen.
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {

  debug:boolean = true;



  // show the first-time notice about credits for free?
  showWelcomeNote:boolean = false;

  since:number = null;

  public navItems: NavData[] = [
    {
      name: 'Projects',
      url: '/',
      icon: 'cui-globe',
      badge: {
        variant: 'info',
        text: ''
      }
    }
  ];
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public error: any;
  public navItemsWithProject: NavData[];
  projects: Project[] = [];

  // global billing manipulation (copied from gps-processing, blah, blah)
  public billingSetup: any;

  // testing for a job's result report
  public globalState:any = {};


  constructor(public fourD: FourDService, public service: AppService, public router: Router, public filesHelper:FilesHelperService,
              public snackBar: MatSnackBar, public dialog: MatDialog, public translate: TranslateService, public appComponent: AppComponent,
              public projectService: ProjectService, @Inject(DOCUMENT) _document?: any) {
    super(router, snackBar, dialog);
    //start ngcore-ui stuff
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  //end ngcore-ui stuff
    
    if ( this.debug ) { console.log ( 'dashboard :: constructor()', this.fourD); }
    // console.log ( 'fourD', this.fourD);

    //true until we get projects
    this.loading = true;
    /*
    set active team
     */
    service.onReady().pipe(flatMap(_ =>
      fourD.onReady()
    )).subscribe(s => {

        var accountMeta = this.service.objectMetas.find(o => o.uid == 'Account')
        // console.log ( 'accountMeta', accountMeta);

        if (!fourD.teams.length) {
          this.loading = false
          return
        }
        var toLoad = fourD.teams[0].uid;
        if (this.fourD.activeUser['lastAccount'] && this.fourD.teams.find(t => t.uid == this.fourD.activeUser['lastAccount'])) {
          toLoad = this.fourD.activeUser['lastAccount']
        }


        if (fourD.teams && fourD.teams.length > 0) {
          fourD.setActiveTeam(toLoad).subscribe(a => {
              this.readyFoudD();
            },
            (e) => {
              this.loading = false;
            }
          )
        } else {
          fourD.setActiveTeam(fourD.activeTeam.uid).subscribe(a => {
            this.readyFoudD();
          })
        }

      }, e => {
        this.loading = false;
        this.error = e
        
      console.log(e);
      }
    )

    // check incoming socket messages
    this.fourD.onSocketCommand('*').subscribe(a => {
      // if ( this.debug ) { console.log ( 'dashboard :: constructor() - sub = onSocketCommand()', a); }
      this.checkFilesStatus ( a );
    });

    this.fourD.onSocketCommand('ping').subscribe(a =>
      this.fourD.sendSocket("ping")
    )

    this.fourD.onSocketCommand('credit_change').subscribe(a => {
      
      var account = this.fourD.teams.find(aa => aa.id == a.id)
      account.adhocCredit = a.adhocCredit
      account.autoCredit = a.autoCredit

      console.log('credit', a, account, this.fourD.activeTeam );

    });
    
  }

  checkForWelcomeNote() {

    let me = this.fourD.activeTeam;

    // if haven't seen message before
    if ( ! me['viewedWelcomeNote'] ) {
      this.showWelcomeNote=true;
    }

  }

  dismissedWelcomeNote() {
    this.showWelcomeNote=false;
    this.fourD.activeTeam['viewedWelcomeNote'] = true;
    this.fourD.activeTeam.saveObservable().subscribe();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  checkFilesStatus ( a:any ) {

    const style = 'background-color:orange;font-size:12px;';

    if ( a && a.job_name ) {
      a['type'] = 'Job';
    }

    if ( a && a.type ) {

      switch ( a.type ) {

        case 'Dataset':
          if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=DATASET', style, a); }
          let state = ! a.incomplete ? FileState.UPLOADING : FileState.READY;
          this.filesHelper.update ( a.projectID, a.name, state, a.size );
          // let idx = a.projectID + '-' + a.name; // .substring(a.name.lastIndexOf(".")+1);
          // this.projectService.globalFileState[idx] = a.size;
          break;

          case 'Project':
            if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=PROJECT', style, a); }
            break;

          case 'Job':
            
            if ( this.filesHelper.jobProject[a.job_id] ) {
              if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=JOB ['+a.job_id+'] for project ['+this.filesHelper.jobProject[a.job_id]+']', style, a); }
              this.filesHelper.update ( this.filesHelper.jobProject[a.job_id], a.name, a.info );
            } else {
              if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=JOB unknown project for job ['+a.job_id+']', style, a); }
            }
            
            break;
    
        default:
          if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=OTHER', style, a); }
          break;

      }
      
    } else {
      if ( this.debug ) { console.log ( '%c dashboard :: checkFilesStatus() socket=ELSE', style, a); }
    }

    /* if ( a && a.projectID ) {
      let project = this.projects.find( p => p.id == a.projectID );
      if ( project ) {
        // console.log ( '%c dashboard :: Socket -- project('+a.projectID+')', style, project );
        if ( project[Constants.JOB_RESULT_FOLDER] ) {

          this.fourD.V.searchByObject(Dataset).equals('folder', project[Constants.JOB_RESULT_FOLDER]).queryObservable().subscribe( files => {
            console.log ( '%c dashboard :: Socket -- project('+a.projectID+') files ('+files.items.length+')', style, project, files );
          });
        }
      } else {
        console.log ( '%c dashboard :: Socket -- project('+a.projectID+') not found', style, this.projects );
      }
      
    } */
    
  }


  /*
  logout using this.service.V.logoutObservable() and navigate back to login page
   */
  logout() {
    if ( this.debug ) { console.log ( 'dashboard :: logout()'); }
    this.translate.get(['GENERIC.OK', 'GENERIC.CANCEL', 'NAV.LOGOUT_MESSAGE', 'LOGOUT_ERROR']).pipe(
      mergeMap(trans => {
        this.error = trans['NAV.LOGOUT_ERROR']
        return this.openDialog(trans['NAV.LOGOUT_MESSAGE'], null, trans['GENERIC.OK'], trans['GENERIC.CANCEL'])
      }), mergeMap(result => {
        if (result) {
          this.loading = true

          return this.service.V.logoutObservable()
        } else {
          return observableEmpty()
        }
      }),).subscribe(
      succ => {
        this.loading = false
        this.fourD.clearStates()
        // Go to root page on logout
        this.router.navigateByUrl(AppRoutes.AUTH)
      },
      err => {
        this.loading = false
        this.openSnackBarError(this.error)
      }
    )

  }

  ngOnInit(): void {
    if ( this.debug ) { console.log ( 'dashboard :: ngOnInit() -> setProjects()'); }
    this.projectService.projectsChanged.subscribe(_ => this.setProjects());
  }
  /*
  get ppkProject projects list once ActiveAccount is set
   */
  private readyFoudD() {

    if ( this.debug ) { console.log ( 'dashboard :: readyFouD()', this.fourD.activeTeam); }

    this.fourD.onActiveAccountReady().subscribe(_ => {

      this.checkForWelcomeNote();

      if (this.fourD.projects) {
        this.setProjects();
      }

    }, error1 => {
      console.log('GOT IT error1', error1);
      this.service.V.logoutObservable().subscribe();
      this.loading = false;
    });
  }

  setTeam(a: Account) {

    console.log ( 'switching organisation ...', a );

    var u = this.fourD.V.localSession().user
    u['lastAccount'] = a.uid
    u.saveObservable().subscribe()

    this.loading = true
    this.fourD.setActiveTeam(a.uid).subscribe(s => {
      // this.router.navigate(['/app']);
      this.loading = false;
      this.setProjects();
      // redo the permissions
      // this.service.setupPermissions();
    })
  }

  private setProjects() {
    if ( this.debug ) { console.log ( 'dashboard :: setProjects()'); }
    this.fourD.projects = this.fourD.projects.filter(p => p['ppkProject']);
    this.navItemsWithProject = [
      {
        name: 'Projects',
        url: '/',
        icon: 'cui-globe',
        badge: {
          variant: 'info',
          text: ''
        }
      }
    ];
    this.fourD.projects.forEach(p => {
  
      this.navItemsWithProject.push(
        {
          name: p.name,
          url: '/app/map/' + p.id,
          icon: 'cui-globe',
          badge: {
            variant: 'info',
            text: ''
          }
        }
      );

        // this.reportTest(p);

    });
    this.navItems = this.navItemsWithProject;
    this.projects = this.fourD.projects.sort(this.sortJobs);
    // console.log(this.navItems.length);
    this.loading = false;
  }

  sortJobs ( a, b ) {
    if ( a.dateUpdated > b.dateUpdated ) return -1;
    if ( a.dateUpdated < b.dateUpdated ) return 1;
    return 0;
  }

  reportTest( p:Project ) {


    let jobs = this.fourD.V.searchByObject(Job).equals('parent', p.id).queryObservable();
    
    jobs.subscribe(res => {
      res.items.forEach(job => {
        console.log ( '!['+p.id+'] job ' + job.id, job.name, job.jobType.name );
        
      });
      // console.log ( '!!!!! result', res );
    });
  }



  openIntegrationDialog() {
    this.dialog.open(ApiComponent, {
      width: '75%',
      data: { title: 'API integration' }
    }).afterClosed().subscribe(value => {
      if (value) {
      }
    })
  }

  openBillingDialog() {
    this.dialog.open(BillingSetupComponent, {
      width: '75%',
      data: { title: 'BILLING.BILLING_SETUP' }
    }).afterClosed().subscribe(value => {
      if (value) {
        this.projectService.currentJob['amountPaid'] = true;
        this.billingSetup = value;

      }
    })
  }

  refreshBilling() {
    this.fourD.V.searchByObject(BillingSetup)
      .equals('account', this.fourD.activeTeam.id)
      .equals('active', true)
      .queryObservable().pipe(
        tap(b => {
          if (b.items.length) {
            console.log(b.items)
            this.billingSetup = b.items[0];
          }
        })
      ).subscribe();
  }
}
