import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FileUtilService} from '../../../commons/service/file-util.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '../../../commons/app.service';
import {AccountPlan, BillingSetup, FourDService, Project} from '4d-mapper';
import {ProjectService} from '../../../commons/project.service';
import {GpsProcessingDialogComponent} from '../gps-processing-dialog/gps-processing-dialog.component';
import {BaseComponent} from '../../../commons/BaseComponent';
import {FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CountryList} from './countryList';
import {Element as StripeElement, Elements, ElementsOptions, StripeService} from 'ngx-stripe';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-billing-setup',
  templateUrl: './billing-setup.component.html',
  styleUrls: ['./billing-setup.component.scss']
})
export class BillingSetupComponent extends BaseComponent implements OnInit {

  project: Project;


  error: string
  success: string

  billingSetup: BillingSetup

  selectedPlan: AccountPlan
  isNewCard: boolean

  BILLING_QMAP = [
    {id: 'name'},
    {id: 'company'},
    {id: 'email'},
    {id: 'phone'}
  ]

  countryList = CountryList.countryList;

  stripeTest: FormGroup;
  elementsOptions: ElementsOptions = {
    locale: 'auto'
  };

  elements: Elements;
  card: StripeElement;
  private stripeInitialized: boolean = false;

  updating: boolean = false;
  plans: AccountPlan[] = [];

  showOther:boolean = false;
  otherValue:number = 10;
  

  constructor(
    public dialogRef: MatDialogRef<BillingSetupComponent>, @Inject(MAT_DIALOG_DATA) public data: any
    , public fourD: FourDService, public service: AppService, public router: Router,
    public snackBar: MatSnackBar, public dialog: MatDialog, private activeRoute: ActivatedRoute,
    public translate: TranslateService,
    private stripeService: StripeService,
    private fb: FormBuilder) {
    super(router, snackBar, dialog);

    this.plans = this.fourD.plans.filter(a => a.isTrial && a.seq < 0)
    this.refreshBilling().subscribe();
    
  }


  saveAndRefresh() {

    this.billingSetup.saveObservable().subscribe(_ => this.refreshBilling().subscribe())
  }

  refreshBilling() {
    return this.fourD.V.searchByObject(BillingSetup)
      .equals('account', this.fourD.activeTeam.id)
      .equals('active', true)
      .queryObservable().pipe(
        tap(b => {
          console.log ( 'billing b', b);
          if (b.items.length) {
            // console.log(b.items)
            this.billingSetup = b.items[0];
            // this.billingSetup['plan'] = this.plans[0].id;
            this.billingSetup.fetchObservable().subscribe(s => {
              if (s.cards && s.cards.length) {
                this.billingSetup['source'] = s.cards.find(c => c.default).card
              }
              else {
                this.doNewCard()

              }
            })
          }
          else {
            this.billingSetup = new BillingSetup(this.fourD.V).set({account: this.fourD.activeTeam});
            // console.log ( 'billing s', this.billingSetup, this.plans );
            // this.billingSetup['plan'] = this.plans[0].id;
            this.doNewCard()
          }
        })
      )
  }

  topup(amount:Number){
    this.openDialog('Confirm','Your adding ' + amount + ' credit, this will cost $' + amount,'Purchase','Cancel').subscribe(s => {
      if(s){
        this.fourD.activeTeam.buyCredit(amount).subscribe(s => {
          this.openDialog('Success','Credit added')
        }, e=> this.openDialog('error',e.message))
      }
    })
  }  


  initStripe() {


    // const Stripe$: Observable<any> = this.stripeService.getStripeReference();

    // this is a reference to the stripe elements object
    // const stripe = this.stripeService.getInstance();
    /*var stripeKey = (location.host != 'sandpit.4dmapper.com' && location.host != 'localhost:4200') ?
      'pk_live_BvlLxMoAHydR4u9ikMg8wOqW00NN5CvkoI'
      :
      'pk_test_SLqJQBTWrWzdQbx3B0JcxK4U00xBFvO1wK'
*/
    this.service.V.api('/payment/info').subscribe(key => {
      var stripeKey = key.public_key

    
      this.stripeService.setKey(stripeKey);
      this.stripeService.elements(this.elementsOptions)
        .subscribe(elements => {
          this.elements = elements;
          if (this.billingSetup['uid'])
            this.stripeService.stripe
          if (!this.card) {
            this.card = this.elements.create('card', {
              style: {
                base: {
                  iconColor: '#666EE8',
                  color: '#31325F',
                  lineHeight: '40px',
                  fontWeight: 300,
                  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                  fontSize: '18px',
                  '::placeholder': {
                    color: '#CFD7E0'
                  }
                }
              },
              hidePostalCode: true
            });

            this.card.on('blur', s => {
              this.stripeService
                .createSource(this.card)
                .subscribe(result => {
                  if (result.source) {
                    // Use the token to create a charge or a customer
                    // https://stripe.com/docs/charges
                    console.log(result.source);
                    this.billingSetup['source'] = result.source.id;
                  } else if (result.error) {
                    // Error creating the token
                    this.openSnackBarError(result.error.message);
                  }
                });
            })


          }

        });
      });
    this.stripeInitialized = true;
  }

  doNewCard() {
    this.isNewCard = true
    delete this.billingSetup['source']
    if (!this.stripeInitialized) {
      this.initStripe();
    }

    setTimeout(
      () => {
        if(this.card)
          this.card.mount('#card-element1')
      },
      1000
    );

  }

  updateDetails() {
    if (!this.billingSetup['source']) {
      this.openSnackBarError('Please provide card details');
      return;
    } else {
      this.updating = true;
        this.billingSetup.saveObservable().subscribe(s => {
          this.success = 'YA';
          this.updating = true;
          setTimeout(() => this.dialogRef.close(this.billingSetup), 2000)
        }, e => {
          this.updating = true;
          this.error = e.message;
        })
    }
  }


  cancel() {
    this.dialogRef.close()
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {
  }

}
