export class AppRoutes {
  static MAIN = 'app';
  static AUTH = 'auth';
  static ACTIVATE_ACC = "activate/:account/:token"
  static ACTIVATE_ACC_GRP = "activate/:account/:groupUID/:token"
  static ACTIVATE_NO_ACC = "activate/:token"
}

export const AppConstants = Object.freeze({
  STATUS_SCHEDULED: 1,
  STATUS_PROGRESS: 2,
  STATUS_FINISHED: 3,
  SCREEN_MOBILE: 768,
  DATAUM_ASSET: "datumAsset"
})

export const PageState = Object.freeze({
  NOT_READY: 'not_ready',
  READY: 'ready',
  LOADING: 'loading',
  PROCESSING: 'processing',
  ESTIMATING: 'estimating',
  ERROR: 'error'
})

export const FileState = Object.freeze({
  NOT_READY: null,
  READY: 'ready',
  QUEUEING: 'queuing',
  UPLOADING: 'uploading',
  PROCESSING: 'processing',
  ESTIMATING: 'estimating',
  ERROR: 'error'
})

export class Constants {
  static UPLOAD_ERROR_MESSAGE: string = 'Error while uploading file ';
  static UPLOAD_SUCCESS_MESSAGE: string = 'Successfully uploaded file ';
  static ERROR_MESSAGE: string = 'Error, Please try again';
  static SUCCESS_MESSAGE: string = 'Success';
  static SNACKBAR_DURATION = 4000;
  static JOB_RESULT_FROM: string = 'Job Result From ';
  static JOB_RESULT_FOLDER: string = 'job_result_folder';


  static isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

}

export class ProcessingOptions {
  static SELECT_MISSION_DATA = 'SELECT_MISSION_DATA';
  static SELECT_PROCESSING_OPTION = 'SELECT_PROCESSING_OPTION';
  static SELECT_REVIEW_SUBMIT = 'SELECT_REVIEW_SUBMIT';

}

export class Models {
  static DJI_P4RTK = 'DJI_P4RTK';
  static KLAUPPK_AIRCRAFT = 'KLAUPPK_AIRCRAFT';
  static OTHERS = 'OTHERS';
  static GNSS_Airborne = 'GNSS_Airborne';
  static GNSS_Marine = 'GNSS_Marine';
  static GNSS_Pedestrian = 'GNSS_Pedestrian';
  static GNSS_Static = 'GNSS_Static';
  static GNSS_UAV = 'GNSS_UAV';
  static GNSS_Vehicle = 'GNSS_Vehicle';
  static Trimble = 'Trimble';
  static U_Blox = 'U-Blox';
  static Generic_Rinex_Format = 'Generic Rinex Format';

}

export const Notification = Object.freeze({
  INFO: "info-dialog",
  WARN: "warning-dialog",
  ERROR: "error-dialog",
  SUCCESS: "success-dialog"
})
