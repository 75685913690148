import {map, mergeMap, share, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {of as observableOf} from 'rxjs/observable/of'
import {User, Vasat} from 'vasat';
import {HttpClient} from '@angular/common/http';
import {Location} from '@angular/common';
import {ObjectMeta} from './models';

import {Title} from '@angular/platform-browser';
import {QMAP, QMAPService, ServerModel} from 'ng-qmap';


@Injectable({
  providedIn: 'root'
})
export class AppService  {


  private _loading: Observable<AppService>
  vasatInstance: any
  objectMetas: ObjectMeta[] = []
  myProfile: User
  accounts: Account[]

  curAccountObs: BehaviorSubject<Account> = new BehaviorSubject<Account>(null)
  accLoading: boolean = false
  // Toolbar
  toolbar = {title: ''}
  themes = [{name: 'THEME.LIGHT', class: ''}, {name: 'THEME.DARK', class: 'dark-theme'}]
  curThemeClass: string = ''
  languages = [{name: 'English', code: 'en'}, {name: '日本語', code: 'ja'}]
  isSharedLogin: boolean

  constructor(public V: Vasat, private http: HttpClient, private location: Location, private titleService: Title) {
    V.registerClass(User)
    V.onLogout.subscribe( () => {

      delete localStorage['last4d_token']
      delete localStorage['last4d_secret']

      this.myProfile = null
      // Invalidate all vasat session
      this.V.invalidateSession()
      // Go to root page on logout
      //this.router.navigateByUrl(AppRoutes.AUTH)
    })
  }

  onReady(): Observable<AppService> {
    if (this.accounts) {
      return observableOf(this)
    }
    else if (this._loading) {
      return this._loading
    }
    else {
      return this._loading = this.V.onReady().pipe(mergeMap(v => {
        this.isSharedLogin = this.V.localSession() && !this.V.localSession().user

        return this.refreshMe().pipe(
          mergeMap(a => {
            //console.log("getting data")
            return this.refreshData()
          }),
          map(r => {

            this._loading = null
            return this
          }),)
      }), share(),)
    }
  }

  refreshMe() {
    // fetch the api/me user object as well as Account list that this user belongs to
    return this.V.refreshMeUser().pipe(
      map(
        up => {
          this.myProfile = up
          if (!this.myProfile.json) this.myProfile.json = {}  // Simply make json field empty rather than null if it comes null from the server.
        },
        e => this.myProfile = null))
  }

  /**
   * Gets all the app data. The order of the requests matters
   */
  refreshData(): Observable<any> {
    return this.V.searchByObject(ObjectMeta).queryObservable().pipe(tap(o => this.objectMetas = o.items))
  }

  logout() {
    return this.V.logoutObservable().pipe(map(_ => {

    }))
  }

  getUserLocale(): String {
    if (navigator.languages != undefined)
      return navigator.languages[0];
    else
      return navigator.language;
  }

  /**
   * Loads the vasat instace config at '{host}/vasat'
   */
  loadInstanceConfig() {
    return this.V.api('/vasat').pipe(map(config => {
      if (config.app_name) this.titleService.setTitle(config.app_name)
      this.vasatInstance = config
      this.curThemeClass = config.app_branding && config.app_branding.theme ? config.app_branding.theme : ''
    }))
  }

  /**
   * Request email verification to register.
   */
  requestEmailRegistration(email: string) {
    return this.V.api('/access/register', 'post', {email: email}).pipe(map(config => this.vasatInstance = config))
  }

  /**
   * Returns the sufix for the given access group uid. Normally is "_users" or "_admins"
   * @param uid The access group uid to qeury
   */
  getAccessGroupSufix(accessGroupUid: string, accountUid: string) {
    return accessGroupUid.replace(accountUid, '')
  }
}

@Injectable()
export class VasatQMAPService extends QMAPService {
  constructor(private service: AppService) {
    super()
  }

  objectTypeQMap(objString: string): QMAP[] {
    return [{id: 'name', type: 'shorttext', title: 'Name', required: true}]
  }

  objectsForQMap(qMap: QMAP, searchString?: string, superModel?: any): Observable<ServerModel[]> {
    var query = this.service.V.searchByObject(this.service.V.registeredClassForString(qMap.obj_type))
    if (searchString)
      query = query.contains('name', searchString)

    return query.queryObservable().pipe(
      map(i => i.items)
    )
  }

  isModel(obj: any): boolean {
    return !!obj && obj.toJSON
  }

  findCachedModel(qmap: QMAP, id: number): ServerModel {
    return this.service.V.findCached(qmap.obj_type, id)
  }

  makeModel(qmap: QMAP): ServerModel {
    var clazz = this.service.V.registeredClassForString(qmap.obj_type)
    return new clazz(this.service.V)
  }

  canWrite(sm: ServerModel): boolean {
    return sm.permissions ? sm.permissions.find(p => 'api_update') : true
  }

  canDelete(sm: ServerModel): boolean {
    return sm.permissions ? sm.permissions.find(p => 'api_delete') : false
  }

  absURL(m: ServerModel, s: string) {
    return m.file(s) + '?access_token=' + this.service.V.token
  }



  /* more generic and helpful collection of shorties */

  






}
