import { Dataset, Folder, FourDService, Project, Job } from '4d-mapper';
import { Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';
import { forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CesiumContainerComponent } from '../view/map/cesium-container/cesium-container.component';
import { AppService } from './app.service';
import { Constants, Models, FileState } from './constants';
import { FourDInjector } from './FourDInjector';
import { MatTableDataSource } from '@angular/material';
import { MapService } from '../view/map/map.service';
import { FilesHelperService } from './service/files-helper.service';

const Cesium = (<any>window).Cesium;

export class MiaFile {
  
}
export class MiaInputFile extends MiaFile {

}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  public debug:boolean = true;

  public debugSidenav:number = -4;
  
  // if there's a report done for this project
  public zipReport:any = null;

  // global uploads list
  public globalFileState:any = {};

  // refactor; needed files
  // public neededFiles:NeedyFiles = new NeedyFiles();

  // list of all files attached to the project
  public listOfAllFiles:any = null;

  // state of the three pages
  public uploadPageIs:string = FileState.NOT_READY;
  public optionsPageIs:string = FileState.NOT_READY;
  public reviewPageIs:string = FileState.NOT_READY;

  // our states are null (for not ready), 'ready', 'loading', 'processing'

  setUploadPage ( state:string ) {
    // console.log ( 'p_s:: setUploadPage()', state);
    this.uploadPageIs = state;
  }
  setOptionsPage ( state:string ) {
    // console.log ( 'p_s:: setOptionsPage()', state);
    this.optionsPageIs = state;
  }
  setReviewPage ( state:string ) {
    // console.log ( 'p_s:: setReviewPage()', state);
    this.reviewPageIs = state;
  }

  // last error message
  currentErrorMessage:string = null;





  // old code

  imageFileDataChanged: Subject<any> = new Subject();
  dummyImageFileDataChanged: Subject<any> = new Subject();
  processingDatums = [
    'ETRS89(Europe)', 'GDA2020(Australia)', 'GDA94(Australia)', 'WGS84(ITRF 2014, Epoch 2019)', 'NAD83(2011, North America)'
  ];
  processingDatum = this.processingDatums[3];
  selectedTransform: any = 'None';
  imageFileDataName = 'data.text';
  rawPPKFileName = 'raw.bin';
  ppkObsFileName = 'obs.19o';
  klauPPKFileName = 'log.ppk';
  rinexFileName = 'file.obs';
  rinexbase20OFileName = 'base.19o';
  rinexbaseNavFileName = 'base.nav';
  rinexbaseRnxFileName = 'base.rnx';
  rinexbase19nFileName = 'base.19n';
  rinexbase19gFileName = 'base.19g';
  rinexbase19cFileName = 'base.19c';
  rinexbase19hFileName = 'base.19h';
  rinexbase19lFileName = 'base.19l';
  rinexbase19jFileName = 'base.19j';
  rinexbase19iFileName = 'base.19i';

  rinexbase20OChunkFileName = 'baseChunk.19os';
  rinexbaseNavChunkFileName = 'baseChunk.nav';
  rinexbaseRnxChunkFileName = 'baseChunk.rnx';
  rinexbase19nChunkFileName = 'baseChunk.19n';
  rinexbase19gChunkFileName = 'baseChunk.19g';
  rinexbase19cChunkFileName = 'baseChunk.19c';
  rinexbase19hChunkFileName = 'baseChunk.19h';
  rinexbase19lChunkFileName = 'baseChunk.19l';
  rinexbase19jChunkFileName = 'baseChunk.19j';
  rinexbase19iChunkFileName = 'baseChunk.19i';
   
  logFileName = 'base.ubx';
  logFileChunkName = 'baseChunk';
  otherFileChunkName = 'otherFileChunk';
  fullOtherFileName = 'inputFile';
  mrkFileName = 'mark.mrk';
  navigationNavFileName = 'file.nav';
  navigationRnxFileName = 'file.rnx';
  navigation19nFileName = 'file.19n';
  navigation19gFileName = 'file.19g';
  navigation19cFileName = 'file.19c';
  navigation19hFileName = 'file.19h';
  navigation19lFileName = 'file.19l';
  navigation19jFileName = 'file.19j';
  navigation19iFileName = 'file.19i';

  klauPPKFileUploaded = false;
  mrkFileUploaded = false;
  imageFileUploaded = false;
  rawPPKFileUploaded = false;
  obsFileUploaded = false;
  rinexFileUploaded = false;
  navigationNavFileUploaded = false;
  navigationRnxFileUploaded = false;
  navigation19nFileUploaded = false;
  navigation19gFileUploaded = false;
  navigation19cFileUploaded = false;
  navigation19hFileUploaded = false;
  navigation19lFileUploaded = false;
  navigation19jFileUploaded = false;
  navigation19iFileUploaded = false;
  rinexbase20OFileUploaded = false;
  rinexbase20OChunkFileUploaded = false;
  rinexbaseRnxFileUploaded = false;
  rinexbase19nFileUploaded = false;
  rinexbase19gFileUploaded = false;
  rinexbaseNavFileUploaded = false;
  rinexbase19cFileUploaded = false;
  rinexbase19hFileUploaded = false;
  rinexbase19lFileUploaded = false;
  rinexbase19jFileUploaded = false;
  rinexbase19iFileUploaded = false;
  logFileUploaded = false;
  logFileChunkUploaded = false;
  otherFileChunkUploaded = false;
  projectReady: Subject<any> = new Subject<any>();
  projectsChanged: Subject<any> = new Subject<any>();
  projectSettingChange: Subject<any> = new Subject<any>();
  imageFileDataset: Dataset;
  rawPPKFileDataset: Dataset;
  obsFileDataset: Dataset;
  mrkFileDataset: Dataset;
  klauPPKFileDataset: Dataset;
  cesiumPoints: any = [];
  private baseDataPoints = [];
  //.nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
  navigationNavFileDataset: Dataset;
  navigationRnxFileDataset: Dataset;
  navigation19nFileDataset: Dataset;
  navigation19gFileDataset: Dataset;
  navigation19cFileDataset: Dataset;
  navigation19hFileDataset: Dataset;
  navigation19lFileDataset: Dataset;
  navigation19jFileDataset: Dataset;
  navigation19iFileDataset: Dataset;
  rinexbase20OFileDataset: Dataset;
  rinexbase20OChunkFileDataset: Dataset;
  rinexbaseNavFileDataset: Dataset;
  rinexbaseRnxFileDataset: Dataset;
  rinexbase19nFileDataset: Dataset;
  rinexbase19gFileDataset: Dataset;
  rinexbase19cFileDataset: Dataset;
  rinexbase19hFileDataset: Dataset;
  rinexbase19lFileDataset: Dataset;
  rinexbase19jFileDataset: Dataset;
  rinexbase19iFileDataset: Dataset;
  logFileDataset: Dataset;
  logFileChunkDataset: Dataset;
  otherFileChunkDataset: Dataset;
  project: Project;

  snotifyService: SnotifyService;
  jobResultFolder: Folder;

  // .nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
  regExNav = new RegExp('(.*?)\.(nav|NAV)$');
  regExRnx = new RegExp('(.*?)\.(rnx|RNX)$');
  regEx19n = new RegExp('(.*?)\.(19n|19N)$');
  regEx19g = new RegExp('(.*?)\.(19g|19G)$');
  regEx19c = new RegExp('(.*?)\.(19c|19C)$');
  regEx19h = new RegExp('(.*?)\.(19h|19H)$');
  regEx19l = new RegExp('(.*?)\.(19l|19L)$');
  regEx19j = new RegExp('(.*?)\.(19j|19J)$');
  regEx19i = new RegExp('(.*?)\.(19i|19I)$');

  rnxNavigationFileError: boolean;
  loading: boolean;
  rinexbaseNavFiles: any[];
  selectedOption: string;
  gotoNextStep: Subject<string> = new Subject<string>();
  currentJob: Job;
  jobRunning: boolean;
  dataSource: MatTableDataSource<any>;
  startJobSub: Subject<string> = new Subject<string>();
  createJobSub: Subject<any> = new Subject<any>();
  fullLogFileDataset: Dataset;
  fullOtherFileDataset: Dataset;
  fullOtherFileUploaded: boolean;



    // refactoring (@alex)

  // state variables
  viewMode:string = Models.OTHERS;
  filesNeeded:any[] = [];



  constructor(private fourD: FourDService, public service: AppService, public mapService: MapService, public filesHelper:FilesHelperService ) {

    this.snotifyService = FourDInjector.injector.get(SnotifyService);
  }

  
  neededFilesUploaded ( p:Project, cardinality:string = 'or' ) {

    if ( ! p['aircraft'] )
      return null;

    let test = false;

    switch ( p['aircraft'] ) {
      case Models.DJI_P4RTK:
        /* removed hard check on this file to create support for M300/P1
        if ( this.obsFileUploaded ) {
          test = true;
        } else {
          if ( cardinality == 'and' ) {
            break;
          }
        } */
        if ( this.rawPPKFileUploaded ) {
          test = true;
        } else {
          test = false;
          if ( cardinality == 'and' ) {
            break;
          }
        }
        if ( this.imageFileUploaded ) {
          test = true;
        } else {
          test = false;
          if ( cardinality == 'and' ) {
            break;
          }
        }
        break;
      case Models.KLAUPPK_AIRCRAFT:
        if ( this.klauPPKFileUploaded ) {
          test = true;
        }
        break;
      default:
        if ( this.fullOtherFileUploaded ) {
          // console.log ( 'full');
          test = true;
        } else {
          test = false;
          if ( cardinality == 'and' ) {
            break;
          }
        }
        break;
    }
    // console.log ( 'ret', test);
    return test;
  }

  uploadsStillGoing ( p:Project, cardinality:string = 'or' ) {

    if ( ! p['aircraft'] )
      return null;

    let test = false;

    switch ( p['aircraft'] ) {
      case Models.DJI_P4RTK:
        if ( this.obsFileDataset && this.fourD.uploadsRunningMap[this.obsFileDataset.id] ) {
          test = true;
        }
        if ( cardinality == 'and' && this.rawPPKFileDataset && this.fourD.uploadsRunningMap[this.rawPPKFileDataset.id] ) {
          test = true;
        }
        if ( cardinality == 'and' && this.imageFileDataset && this.fourD.uploadsRunningMap[this.imageFileDataset.id] ) {
          test = true;
        }
        break;
      case Models.KLAUPPK_AIRCRAFT:
        if ( this.klauPPKFileDataset && this.fourD.uploadsRunningMap[this.klauPPKFileDataset.id] ) {
          test = true;
        }
        break;
      default:
        if ( this.otherFileChunkDataset && this.fourD.uploadsRunningMap[this.otherFileChunkDataset.id] ) {
          test = true;
        }
        break;
    }

    return test;
  }

  checkStatus ( what:string ) {
    switch ( what ) {
      case 'obs': return this.obsFileDataset && this.fourD.uploadsRunningMap[this.obsFileDataset.id];
      case 'raw': return this.rawPPKFileDataset && this.fourD.uploadsRunningMap[this.rawPPKFileDataset.id];
      case 'img': return this.imageFileDataset && this.fourD.uploadsRunningMap[this.imageFileDataset.id];
      case 'mrk': return this.mrkFileDataset && this.fourD.uploadsRunningMap[this.mrkFileDataset.id];
      case 'other': return this.fullOtherFileDataset && this.fourD.uploadsRunningMap[this.fullOtherFileDataset.id];
      default: return null;
    }
  }

  // this will parse incoming text files, usually from 
  parseTextFileFromBackend ( text:string ) {


  }



  //set true if datasets are uploaded
  checkUploadedDatasets(project: Project) {

    if ( this.debug ) { console.log ( 'project.service :: checkUploadedDatasets()', project); }

    this.rinexFileUploaded = false;
    //.nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
    this.navigationNavFileUploaded = false;
    this.navigationRnxFileUploaded = false;
    this.navigation19nFileUploaded = false;
    this.navigation19gFileUploaded = false;
    this.navigation19cFileUploaded = false;
    this.navigation19hFileUploaded = false;
    this.navigation19lFileUploaded = false;
    this.navigation19jFileUploaded = false;
    this.navigation19iFileUploaded = false;
    this.rawPPKFileUploaded = false;
    this.klauPPKFileUploaded = false;
    this.rinexbase20OFileUploaded = false;
    this.rinexbaseRnxFileUploaded = false;
    this.rinexbase19nFileUploaded = false;
    this.rinexbase19gFileUploaded = false;
    this.rinexbaseNavFileUploaded = false;
    this.rinexbase19cFileUploaded = false;
    this.rinexbase19hFileUploaded = false;
    this.rinexbase19lFileUploaded = false;
    this.rinexbase19jFileUploaded = false;
    this.rinexbase19iFileUploaded = false;
    this.logFileUploaded = false;
    this.fullOtherFileUploaded = false;
    let filesArray = [this.rinexbase20OFileName,
    this.rinexbaseNavFileName,
    this.rinexbaseRnxFileName,
    this.rinexbase19nFileName,
    this.rinexbase19gFileName,
    this.rinexbase19cFileName,
    this.rinexbase19hFileName,
    this.rinexbase19lFileName,
    this.rinexbase19jFileName,
    this.rinexbase19iFileName,
    this.logFileName];


    if (project['aircraft'] === Models.DJI_P4RTK) {

      //this.neededFiles.add ( 'img', 'data.txt' );
      //this.neededFiles.add ( 'obs', 'obs.19o' );
      //this.neededFiles.add ( 'raw', 'raw.bin' );
      //this.neededFiles.add ( 'mrk', 'mark.mrk' );

      this.imageFileUploaded = false;
      this.mrkFileUploaded = false;
      this.obsFileUploaded = false;
      filesArray.push(this.imageFileDataName, this.ppkObsFileName, this.rawPPKFileName, this.mrkFileName);

    } else if (project['aircraft'] == Models.KLAUPPK_AIRCRAFT) {

      //this.neededFiles.add ( 'klau', 'log.ppk' );

      filesArray.push(this.klauPPKFileName);

    } else {

      filesArray.push(this.rinexFileName, this.navigationNavFileName, this.navigationRnxFileName, this.navigation19nFileName, 
        this.navigation19gFileName, this.navigation19cFileName, this.navigation19hFileName, this.navigation19lFileName, 
        this.navigation19jFileName, this.navigation19iFileName);
    }

    if ( this.debug ) { console.log ( 'project.service :: checkUploadedDatasets() find these = filesArray', filesArray ); }

    /*
    this.fourD.V.searchByObject(Dataset).equals('folder', project[Constants.JOB_RESULT_FOLDER]).queryObservable().subscribe( files => {

      if ( this.debug ) { console.log ( 'project.service :: checkUploadedDatasets() found files', files ); }
      // console.log ( '###', files );

    });
*/
    
    const arr = filesArray.map(f => {
      // this.filesHelper.add ( project.id, f );
      return this.fourD.V.searchByObject(Dataset).equals('folder', project[Constants.JOB_RESULT_FOLDER])
        .equals('name', f).queryObservable();
    });

    if (project['aircraft'] !== Models.DJI_P4RTK) {
      let i =  this.fourD.V.searchByObject(Dataset).equals('folder', project[Constants.JOB_RESULT_FOLDER])
      .contains('name', this.fullOtherFileName).queryObservable();
      arr.push( i );
      // this.filesHelper.add ( project.id, this.fullOtherFileName );
    }

    // if ( this.debug ) { console.log ( 'project.service :: checkUploadedDatasets() found files', arr ); }

    this.listOfAllFiles = [];

    return forkJoin(arr).pipe(map(value => {
      
      // console.log ( '@@@', value );

      if (value && value.length > 0) {
        value.forEach(v => {
          if (v.items.length > 0 && ! v.items[0].incomplete ) {

            let filename = v.items[0].name;

            this.listOfAllFiles.push( filename );
            if ( this.debug ) { console.log ( 'project.service :: checkUploadedDatasets() adding ['+filename+'] to final list', v.items[0] ); }

            this.filesHelper.update ( project.id, filename, FileState.READY );

            if (v.items[0].name == this.imageFileDataName) this.imageFileUploaded = true;
            else if (v.items[0].name == this.ppkObsFileName) this.obsFileUploaded = true;
            else if (v.items[0].name == this.mrkFileName) this.mrkFileUploaded = true;
            else if (v.items[0].name == this.rawPPKFileName) this.rawPPKFileUploaded = true;
            else if (v.items[0].name == this.klauPPKFileName) this.klauPPKFileUploaded = true;
            else if (v.items[0].name == this.rinexFileName) this.rinexFileUploaded = true;
            //.nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
            else if (v.items[0].name == this.navigationNavFileName) this.navigationNavFileUploaded = true;
            else if (v.items[0].name == this.navigationRnxFileName) this.navigationRnxFileUploaded = true;
            else if (v.items[0].name == this.navigation19nFileName) this.navigation19nFileUploaded = true;
            else if (v.items[0].name == this.navigation19gFileName) this.navigation19gFileUploaded = true;
            else if (v.items[0].name == this.navigation19cFileName) this.navigation19cFileUploaded = true;
            else if (v.items[0].name == this.navigation19hFileName) this.navigation19hFileUploaded = true;
            else if (v.items[0].name == this.navigation19lFileName) this.navigation19lFileUploaded = true;
            else if (v.items[0].name == this.navigation19jFileName) this.navigation19jFileUploaded = true;
            else if (v.items[0].name == this.navigation19iFileName) this.navigation19iFileUploaded = true;
            else if (v.items[0].name == this.rinexbase20OFileName) {
              this.rinexbase20OFileDataset = v.items[0];
              this.rinexbase20OFileUploaded = true;
            } else if (v.items[0].name == this.rinexbaseRnxFileName) {
              this.rinexbaseRnxFileDataset = v.items[0];
              this.rinexbaseRnxFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19nFileName) {
              this.rinexbase19nFileDataset = v.items[0];
              this.rinexbase19nFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19gFileName) {
              this.rinexbase19gFileDataset = v.items[0];
              this.rinexbase19gFileUploaded = true;
            } else if (v.items[0].name == this.rinexbaseNavFileName) {
              this.rinexbaseNavFileDataset = v.items[0];
              this.rinexbaseNavFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19cFileName) {
              this.rinexbase19cFileDataset = v.items[0];
              this.rinexbase19cFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19gFileName) {
              this.rinexbase19gFileDataset = v.items[0];
              this.rinexbase19gFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19hFileName) {
              this.rinexbase19hFileDataset = v.items[0];
              this.rinexbase19hFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19lFileName) {
              this.rinexbase19lFileDataset = v.items[0];
              this.rinexbase19lFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19jFileName) {
              this.rinexbase19jFileDataset = v.items[0];
              this.rinexbase19jFileUploaded = true;
            } else if (v.items[0].name == this.rinexbase19iFileName) {
              this.rinexbase19iFileDataset = v.items[0];
              this.rinexbase19iFileUploaded = true;
            } else if (v.items[0].name == this.logFileName) {
              this.logFileDataset = v.items[0];
              this.logFileUploaded = true;
            } else if (v.items[0].name.includes(this.fullOtherFileName)) {
              this.fullOtherFileDataset = v.items[0];
              this.fullOtherFileUploaded = true;
            }


          }
        })
      }
    }))
  }

  plotBaseData(project: Project) {
    if (project['baseData']) {
      project['baseData'].forEach(data => {
        this.baseDataPoints.push(this.mapService.viewer.entities.add({
          name: 'Red cone',
          position: Cesium.Cartesian3.fromDegrees(data.GPSLongitude, data.GPSLatitude, data.GPSAltitude),
          point: {
            pixelSize: 20,
            color: Cesium.Color.RED,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 1
          },
        }))
      })
    }
  }

  plotLineBaseDataToImageData(project: Project) {
    if (project['baseData'] && project['baseData'].length > 0 && project['imageFileDatas'] && project['imageFileDatas'].length > 0) {
      project['baseData'].forEach(data => {
        this.baseDataPoints.push(this.mapService.viewer.entities.add({
          polyline: {
            name: 'line',
            positions: [Cesium.Cartesian3.fromDegrees(data.GPSLongitude, data.GPSLatitude, data.GPSAltitude), Cesium.Cartesian3.fromDegrees(project['imageFileDatas'][0].GPSLongitude, project['imageFileDatas'][0].GPSLatitude, project['imageFileDatas'][0].GPSAltitude)],
            arcType: Cesium.ArcType.GEODESIC,
            width: 2,
            material: new Cesium.PolylineOutlineMaterialProperty({
              color: Cesium.Color.WHITE,
              outlineColor: Cesium.Color.WHITE

            }),
            depthFailMaterial: new Cesium.PolylineDashMaterialProperty({
              width: 0.2,
              color: Cesium.Color.WHITE,
              dashLength: 4.0

            }),
            perPositionHeight: false, // if true then it will follow real height
            classificationType: Cesium.ClassificationType.BOTH,
            clampToGround: true,
          }
        }))
      })
    }
  }

  /*
  used to upload rawppk , obs file , klauppk, rinex
   */
  uploadFile(filez, fileName, isFullLogFile?: boolean, isFullOtherFile?: boolean) {

    let size = filez ? filez.size : 0;

    if ( this.debug ) { 
      console.log ( 'project.service :: uploadFile()', size, filez, fileName );
    }

    if ( size < 10 ) {
      return fileName;
    }

    if (filez) {
      if (fileName == this.rawPPKFileName && filez.name.indexOf('PPKRAW') == -1) {
        // this.snotifyService.error('Please upload RAWPPK file');
        return;
      }
      if (this.project[Constants.JOB_RESULT_FOLDER]) {
        // destroy older datasets
        this.fourD.V.searchByObject(Dataset)
          .equals('folder', this.project[Constants.JOB_RESULT_FOLDER])
          .equals('name', fileName).queryObservable()
          .subscribe(value => {
            if (value && value.items.length > 0) {
              forkJoin(value.items.map(i => i.destroyObservable())).subscribe(_ => {
                this.startUploadingFiles(filez, fileName, isFullLogFile, isFullOtherFile);
              })
            } else {
              this.startUploadingFiles(filez, fileName, isFullLogFile, isFullOtherFile);
            }
          })
      } else {
        this.startUploadingFiles(filez, fileName, isFullLogFile, isFullOtherFile);
      }
    }
    return false;
  }

  /*
   for rawppk, klauppk , rinex, and obs file
    */
  startUploadingFiles(filez, fileName, isFullLogFile?: boolean, isFullOtherFile?: boolean) {
    if ( this.debug ) { console.log ( 'project.service :: startUploadingFiles()', filez, fileName ); }
    var blank = new Dataset(this.service.V).set({
      name: fileName,
      incomplete_offset: 0,
      incomplete_seq: 0,
      incomplete_total: length,
      incomplete: true,
      projectID: this.project.id,
      folder: this.jobResultFolder,
    });
    blank.saveObservable().subscribe(ds => {

      this.filesHelper.add ( this.project.id, fileName );

      let idx = this.project.id + '-' + fileName; // .substring(fileName.lastIndexOf(".")+1);
      this.globalFileState[idx] = 'uploading';
      var a = this.fourD.uploadFile(ds, filez, 'data', 19000000);
      if (isFullLogFile) {
        this.fullLogFileDataset = ds;
      } else if (isFullOtherFile) {
        this.fullOtherFileDataset = ds;

      }
      a.error = (e) => {
        this.snotifyService.error('failed to upload data', e);
        this.setFileuploadedFalse(fileName);
      }
      this.setFileUploadedAndDataset(fileName, ds);

    })
  }

  private setFileUploadedAndDataset(fileName, ds) {
    if ( this.debug ) { console.log ( 'project.service :: setFileUploadedAndDataset()', fileName, ds ); }
    if (fileName === this.ppkObsFileName) {
      this.obsFileUploaded = true;
      this.obsFileDataset = ds;
    } else if (fileName === this.mrkFileName) {
      this.mrkFileUploaded = true;
      this.mrkFileDataset = ds;
    } else if (fileName === this.rawPPKFileName) {
      this.rawPPKFileUploaded = true;
      this.rawPPKFileDataset = ds;
    } else if (fileName === this.klauPPKFileName) {
      this.klauPPKFileUploaded = true;
      this.klauPPKFileDataset = ds;
      // this.dialogRef.close(true);
    } else if (fileName === this.rinexFileName) {
      this.rinexFileUploaded = true;
      this.navigationRnxFileDataset = ds;
      //.nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
    } else if (fileName === this.navigationNavFileName) {
      this.navigationNavFileUploaded = true;
      this.navigationNavFileDataset = ds;
    } else if (fileName === this.navigationRnxFileName) {
      this.navigationRnxFileUploaded = true;
      this.navigationRnxFileDataset = ds;
    } else if (fileName === this.navigation19nFileName) {
      this.navigation19nFileUploaded = true;
      this.navigation19nFileDataset = ds;
    } else if (fileName === this.navigation19gFileName) {
      this.navigation19gFileUploaded = true;
      this.navigation19gFileDataset = ds;
    } else if (fileName === this.navigation19cFileName) {
      this.navigation19cFileUploaded = true;
      this.navigation19cFileDataset = ds;
    } else if (fileName === this.navigation19hFileName) {
      this.navigation19hFileUploaded = true;
      this.navigation19hFileDataset = ds;
    } else if (fileName === this.navigation19lFileName) {
      this.navigation19lFileUploaded = true;
      this.navigation19lFileDataset = ds;
    } else if (fileName === this.navigation19jFileName) {
      this.navigation19jFileUploaded = true;
      this.navigation19jFileDataset = ds;
    } else if (fileName === this.navigation19iFileName) {
      this.navigation19iFileUploaded = true;
      this.navigation19iFileDataset = ds;
    } else if (fileName === this.rinexbase20OFileName) {
      this.rinexbase20OFileUploaded = true;
      this.rinexbase20OFileDataset = ds;
    } else if (fileName === this.rinexbase20OChunkFileName) {
      this.rinexbase20OChunkFileUploaded = true;
      this.rinexbase20OChunkFileDataset = ds;
    } else if (fileName === this.rinexbaseNavFileName) {
      this.rinexbaseNavFileUploaded = true;
      this.rinexbaseNavFileDataset = ds;
    } else if (fileName === this.rinexbaseRnxFileName) {
      this.rinexbaseRnxFileUploaded = true;
      this.rinexbaseRnxFileDataset = ds;
    } else if (fileName === this.rinexbase19nFileName) {
      this.rinexbase19nFileUploaded = true;
      this.rinexbase19nFileDataset = ds;
    } else if (fileName === this.rinexbase19gFileName) {
      this.rinexbase19gFileUploaded = true;
      this.rinexbase19gFileDataset = ds;
    } else if (fileName === this.rinexbase19gFileName) {
      this.rinexbase19gFileUploaded = true;
      this.rinexbase19gFileDataset = ds;
    } else if (fileName === this.rinexbase19cFileName) {
      this.rinexbase19cFileUploaded = true;
      this.rinexbase19cFileDataset = ds;
    } else if (fileName === this.rinexbase19hFileName) {
      this.rinexbase19hFileUploaded = true;
      this.rinexbase19hFileDataset = ds;
    } else if (fileName === this.rinexbase19lFileName) {
      this.rinexbase19lFileUploaded = true;
      this.rinexbase19lFileDataset = ds;
    } else if (fileName === this.rinexbase19jFileName) {
      this.rinexbase19jFileUploaded = true;
      this.rinexbase19jFileDataset = ds;
    } else if (fileName === this.rinexbase19iFileName) {
      this.rinexbase19iFileUploaded = true;
      this.rinexbase19iFileDataset = ds;
    } else if (fileName === this.logFileName) {
      this.logFileUploaded = true;
      this.logFileDataset = ds;
    } else if (fileName.includes(this.logFileChunkName)) {
      this.logFileChunkDataset = ds;
    } else if (fileName.includes(this.otherFileChunkName)) {
      this.otherFileChunkDataset = ds;
    }
  }

  private setFileuploadedFalse(fileName) {
    if ( this.debug ) { console.log ( 'project.service :: setFileuploadedFalse()', fileName ); }
    if (fileName === this.ppkObsFileName) this.obsFileUploaded = false;
    else if (fileName === this.mrkFileName) this.mrkFileUploaded = false;
    else if (fileName === this.rawPPKFileName) this.rawPPKFileUploaded = false;
    else if (fileName === this.klauPPKFileName) this.klauPPKFileUploaded = false;
    else if (fileName === this.rinexFileName) this.rinexFileUploaded = false;
    else if (fileName === this.navigationNavFileName) this.navigationNavFileUploaded = false;
    else if (fileName === this.navigationRnxFileName) this.navigationRnxFileUploaded = false;
    else if (fileName === this.navigation19nFileName) this.navigation19nFileUploaded = false;
    else if (fileName === this.navigation19gFileName) this.navigation19gFileUploaded = false;
    else if (fileName === this.navigation19cFileName) this.navigation19cFileUploaded = false;
    else if (fileName === this.navigation19hFileName) this.navigation19hFileUploaded = false;
    else if (fileName === this.navigation19lFileName) this.navigation19lFileUploaded = false;
    else if (fileName === this.navigation19jFileName) this.navigation19jFileUploaded = false;
    else if (fileName === this.navigation19iFileName) this.navigation19iFileUploaded = false;
    else if (fileName === this.rinexbase20OFileName) this.rinexbase20OFileUploaded = false;
    else if (fileName === this.rinexbase20OChunkFileName) this.rinexbase20OChunkFileUploaded = false;
    else if (fileName === this.rinexbaseNavFileName) this.rinexbaseNavFileUploaded = false;
    else if (fileName === this.rinexbaseRnxFileName) this.rinexbaseRnxFileUploaded = false;
    else if (fileName === this.rinexbase19nFileName) this.rinexbase19nFileUploaded = false;
    else if (fileName === this.rinexbase19gFileName) this.rinexbase19gFileUploaded = false;
    else if (fileName === this.rinexbase19gFileName) this.rinexbase19gFileUploaded = false;
    else if (fileName === this.rinexbase19cFileName) this.rinexbase19cFileUploaded = false;
    else if (fileName === this.rinexbase19hFileName) this.rinexbase19hFileUploaded = false;
    else if (fileName === this.rinexbase19lFileName) this.rinexbase19lFileUploaded = false;
    else if (fileName === this.rinexbase19jFileName) this.rinexbase19jFileUploaded = false;
    else if (fileName === this.rinexbase19iFileName) this.rinexbase19iFileUploaded = false;
    else if (fileName === this.logFileName) this.logFileUploaded = false;
    else if (fileName.includes(this.logFileChunkName)) {
      this.logFileChunkUploaded = false;
    } else if (fileName.includes(this.otherFileChunkName)) {
      this.otherFileChunkUploaded = false;
    }

  }

  /*
  create or set jobResultFolder
   */
  setFolder() {
    if ( this.debug ) { console.log ( 'project.service :: setFolder()' ); }
    if (!this.project[Constants.JOB_RESULT_FOLDER]) {
      // if PPKAero folder is set at account level then find it and create a subfolder under that for all the projects jobs
      if (this.fourD.activeTeam['PPKAero_FolderID']) {
        this.fourD.V.searchByObject(Folder).equals('id', this.fourD.activeTeam['PPKAero_FolderID']).queryObservable()
          .subscribe(f => {
            if (f) {
              this.createProjectFolder(f.items[0]);
            }
          });
      } else {
        // create new PPKAero folder at account level
        const folder = new Folder(this.fourD.V);
        folder.name = 'PPKAero';
        folder.saveObservable().subscribe(f => {
          // save the folder id to the account
          this.fourD.activeTeam['PPKAero_FolderID'] = f.id;
          this.fourD.activeTeam.saveObservable().subscribe(_ => {
            // create a folder for the current project
            this.createProjectFolder(f);
          });
        });
      }

    } else {
      this.fourD.V.searchByObject(Folder).equals('id', this.project[Constants.JOB_RESULT_FOLDER]).queryObservable().subscribe(value => {
        if (value && value.items.length > 0) {
          this.jobResultFolder = value.items[0];
          this.checkUploadedDatasets(this.project).subscribe();
        }
      });
    }
  }

  private createProjectFolder(parentFolder: Folder) {
    if ( this.debug ) { console.log ( 'project.service :: createProjectFolder()' ); }
    let folder = new Folder(this.fourD.V);
    folder.parent = parentFolder;
    folder.name = this.project.name;
    folder.saveObservable().subscribe((sf) => {
      this.project[Constants.JOB_RESULT_FOLDER] = sf.id;
      this.jobResultFolder = folder;
      this.project.saveObservable().subscribe();
      this.checkUploadedDatasets(this.project).subscribe();
    });
  }

  /*
 read the NavigationFile
  */
  public readNavigationFile(fileList: any, isBaseFile = false): void {
    if ( this.debug ) { console.log ( 'project.service :: readNavigationFile()', fileList ); }
    if (fileList) {
      var filesNav = 0;
      var filesRnx = 0;
      var files19n = 0;
      var files19g = 0;
      var files19c = 0;
      var files19h = 0;
      var files19l = 0;
      var files19j = 0;
      var files19i = 0;
      //validation for duplicate files
      for (let file of fileList) {
        filesNav = this.validateExtenstionAndUpdateCounter(this.regExNav, file, filesNav);
        if (filesNav > 1) {
          this.snotifyService.warning('Duplicate .nav file uploaded, please upload single .nav file');
          return;
        }
        filesRnx = this.validateExtenstionAndUpdateCounter(this.regExRnx, file, filesRnx);
        if (filesRnx > 1) {
          this.snotifyService.warning('Duplicate .rnx file uploaded, please upload single .rnx file');
          return;
        }
        files19n = this.validateExtenstionAndUpdateCounter(this.regEx19n, file, files19n);
        if (files19n > 1) {
          this.snotifyService.warning('Duplicate .19n file uploaded, please upload single .19n file');
          return;
        }
        files19g = this.validateExtenstionAndUpdateCounter(this.regEx19g, file, files19g);
        if (files19g > 1) {
          this.snotifyService.warning('Duplicate .19g file uploaded, please upload single .19g file');
          return;
        }
        files19c = this.validateExtenstionAndUpdateCounter(this.regEx19c, file, files19c);
        if (files19c > 1) {
          this.snotifyService.warning('Duplicate .19c file uploaded, please upload single .19c file');
          return;
        }
        files19h = this.validateExtenstionAndUpdateCounter(this.regEx19h, file, files19h);
        if (files19h > 1) {
          this.snotifyService.warning('Duplicate .19h file uploaded, please upload single .19h file');
          return;
        }
        files19l = this.validateExtenstionAndUpdateCounter(this.regEx19l, file, files19l);
        if (files19l > 1) {
          this.snotifyService.warning('Duplicate .19l file uploaded, please upload single .19l file');
          return;
        }
        files19j = this.validateExtenstionAndUpdateCounter(this.regEx19j, file, files19j);
        if (files19j > 1) {
          this.snotifyService.warning('Duplicate .19j file uploaded, please upload single .19j file');
          return;
        }
        files19i = this.validateExtenstionAndUpdateCounter(this.regEx19i, file, files19i);
        if (files19i > 1) {
          this.snotifyService.warning('Duplicate .19i file uploaded, please upload single .19i file');
          return;
        }
      }
      this.uploadNavigationFiles(fileList, isBaseFile);
    }
  }

  private uploadNavigationFiles(fileList: any, isBaseFile = false, isChunkBaseFile = false) {
    if ( this.debug ) { console.log ( 'project.service :: uploadNavigationFiles()', fileList ); }
    this.rinexbaseNavFiles = [];

    for (let file of fileList) {
      if ((this.regExRnx.test(file.name))) {
        this.startReadingRnxNavigationFile(file, isBaseFile);
      } else {
        //.nav,.rnx,.19n,.19g,.19c,.19h,.19l,.19j,.19i
        if ((this.regExNav.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbaseNavFileName : this.navigationNavFileName);
        } else if ((this.regEx19n.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19nFileName : this.navigation19nFileName);
        } else if ((this.regEx19g.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19gFileName : this.navigation19gFileName);
        } else if ((this.regEx19c.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19cFileName : this.navigation19cFileName);
        } else if ((this.regEx19h.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19hFileName : this.navigation19hFileName);
        } else if ((this.regEx19l.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19lFileName : this.navigation19lFileName);
        } else if ((this.regEx19j.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19jFileName : this.navigation19jFileName);
        } else if ((this.regEx19i.test(file.name))) {
          this.uploadFile(file, isBaseFile ? this.rinexbase19iFileName : this.navigation19iFileName);
        }
      }
    }
  }


  private validateExtenstionAndUpdateCounter(regExNav, fileList: any, filesNav: number) {
    if ((regExNav.test(fileList.name))) {
      filesNav++
    }
    return filesNav;
  }

  /*
     read the NavigationFile
     */
  startReadingRnxNavigationFile(file, isBaseFile = false) {
    if ( this.debug ) { console.log ( 'project.service :: startReadingRnxNavigationFile()', file ); }
    this.loading = true;
    let reader: FileReader = new FileReader();
    reader.onload = (readerEvent) => {
      try {
        var enc = new TextDecoder('utf-8');
        var str = enc.decode(readerEvent.target['result']);
        if (str.indexOf('NAV') == -1) this.rnxNavigationFileError = true;
      } catch (error) {
        console.log('Error while reading file: ' + file.name, error);
        this.loading = false;
      }
    };
    reader.onloadend = () => {
      console.log('reading finished');
      if (!this.rnxNavigationFileError) {
        if (this.project[Constants.JOB_RESULT_FOLDER]) {
          this.fourD.V.searchByObject(Dataset).equals('folder', this.project[Constants.JOB_RESULT_FOLDER]).equals('name', this.navigationNavFileName).queryObservable().subscribe(value => {
            if (value && value.items.length > 0) {
              forkJoin(value.items.map(i => i.destroyObservable())).subscribe(_ => {
                this.uploadFile(file,
                  isBaseFile ? this.rinexbaseRnxFileName : this.navigationRnxFileName);
              })
            } else {
              this.uploadFile(file,
                isBaseFile ? this.rinexbaseRnxFileName : this.navigationRnxFileName);
            }
          });
        } else {
          this.uploadFile(file,
            isBaseFile ? this.rinexbaseRnxFileName : this.navigationRnxFileName);
        }
      } else {
        this.snotifyService.error('Please upload navigation file');
      }
      this.loading = false;

    }
    var blob = file.slice(0, 200);
    reader.readAsArrayBuffer(blob);

  }

}
