import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import { SnotifyType } from 'ng-snotify/snotify/types/snotify.type';
import { Observable, Subscription } from 'rxjs';
import { AppConstants, AppRoutes, Constants, Notification } from './constants';
import { AppDialog } from './dialog/app-dialog/app-dialog.component';
import { FourDInjector } from './FourDInjector';

export class BaseComponent {

  // refactoring; these are the three main states that all components should use to set or get state
  isLoading:boolean = false;
  isEstimating:boolean = false;
  isUploading:boolean = false;
  isProcessing:boolean = false;

  // old code

  loading: boolean = false
  routes = AppRoutes
  appConstants = AppConstants

  snotifyService: SnotifyService; //= AppModule.injector.get(SnotifyService)
  translate: TranslateService; //= AppModule.injector.get(TranslateService)
  subs: Subscription[] = [];

  constructor(public router: Router, public snackBar: MatSnackBar, public dialog: MatDialog) {
    this.snotifyService = FourDInjector.injector.get(SnotifyService);
    this.translate = FourDInjector.injector.get(TranslateService);
  }

  private openSnackBar(message: string, action: string = '', type?: string, meta?: any) {
    if (message == null) message = ''
    if (action == null) action = ''
    this.translate.get([message, action, 'DIALOG.SUCCESS', 'DIALOG.INFO', 'DIALOG.WARNING', 'DIALOG.ERROR'], meta)
      .subscribe(param => {
        let successTitle = param['DIALOG.SUCCESS']
        let infoTitle = param['DIALOG.INFO']
        let warningTitle = param['DIALOG.WARNING']
        let errorTitle = param['DIALOG.ERROR']
        if (message) message = param[message]
        if (action) action = param[action]

        if (this.isMobile()) {
          return this.snackBar.open(message, action, {
            duration: Constants.SNACKBAR_DURATION,
            panelClass: type ? type : ''
          })
        } else if (action) {
          this.snotifyService.confirm(message, {
            showProgressBar: false,
            closeOnClick: false,
            type: this.getSnotifyType(type),
            buttons: [
              {
                text: action, action: (toast) => {
                  this.snotifyService.remove(toast.id);
                }, bold: true
              }
            ]
          })
        } else if (type == Notification.INFO) {
          this.snotifyService.info(message, infoTitle)
        } else if (type == Notification.SUCCESS) {
          this.snotifyService.success(message, successTitle)
        } else if (type == Notification.WARN) {
          this.snotifyService.warning(message, warningTitle)
        } else if (type == Notification.ERROR) {
          this.snotifyService.error(message, errorTitle)
        } else {
          this.snotifyService.simple(message)
        }

      })

  }

  getSnotifyType(type: string): SnotifyType {
    if (type == Notification.INFO) return 'info'
    else if (type == Notification.SUCCESS) return 'success'
    else if (type == Notification.ERROR) return 'error'
    else if (type == Notification.WARN) return 'warning'
    else return 'simple'
  }

  openSnackBarInfo(message: string, action?: string, meta?: any) {
    return this.openSnackBar(message, action, Notification.INFO, meta)
  }

  openSnackBarSuccess(message: string, action?: string, meta?: any) {
    return this.openSnackBar(message, action, Notification.SUCCESS, meta)
  }

  openSnackBarError(message: string, action?: string, meta?: any) {
    return this.openSnackBar(message, action, Notification.ERROR, meta)
  }

  openSnackBarWarn(message: string, action?: string, meta?: any) {
    return this.openSnackBar(message, action, Notification.WARN, meta)
  }

  openDialog(title: string, desc?: string, posBtn?: string, negBtn?: string, input?: any, isExternalURL?: boolean, error?: { status, message }): Observable<any> {

    let dialogRef = this.dialog.open(AppDialog, {
      width: '350px',
      data: {title: title, text: desc, posBtn: posBtn, negBtn: negBtn, input: input, isExternalURL: isExternalURL, error: error}
    });

    return dialogRef.afterClosed()
  }

  openConfirmDelete(cb: Function, name: string) {
    this.openDialogWithMeta({deletename: name}, 'GENERIC.DIALOG.DELETE.TITLE', 'GENERIC.DIALOG.DELETE.MSG', 'GENERIC.YES', 'GENERIC.NO').subscribe(s => {
      if (s)
        cb()
    })

  }

  openDialogWithMode(mode: string, title: string, desc?: string, posBtn?: string, negBtn?: string, input?: any, width?: string, error?: { status, message }): Observable<any> {

    let dialogRef = this.dialog.open(AppDialog, {
      width: width ? width : '80%',
      data: {title: title, text: desc, posBtn: posBtn, negBtn: negBtn, input: input, error: error, mode: mode}
    });

    return dialogRef.afterClosed()
  }


  openDialogWithModeAndFilter(mode: string, filter: any, title: string, desc?: string, posBtn?: string, negBtn?: string, input?: any, error?: { status, message }): Observable<any> {

    let dialogRef = this.dialog.open(AppDialog, {
      width: '80%',
      data: {
        title: title,
        text: desc,
        posBtn: posBtn,
        negBtn: negBtn,
        input: input,
        error: error,
        mode: mode,
        filter: filter
      }
    });

    return dialogRef.afterClosed()
  }

  openDialogWithModeAndFilterAndMeta(meta: any, mode: string, filter: any, title: string, desc?: string, posBtn?: string, negBtn?: string, input?: any, error?: { status, message }): Observable<any> {

    let dialogRef = this.dialog.open(AppDialog, {
      width: '80%',
      data: {
        meta: meta,
        title: title,
        text: desc,
        posBtn: posBtn,
        negBtn: negBtn,
        input: input,
        error: error,
        mode: mode,
        filter: filter
      }
    });

    return dialogRef.afterClosed()
  }

  openDialogWithMeta(meta: any, title: string, desc?: string, posBtn?: string, negBtn?: string, input?: any, error?: { status, message }): Observable<any> {

    let dialogRef = this.dialog.open(AppDialog, {
      width: '350px',
      data: {title: title, text: desc, posBtn: posBtn, negBtn: negBtn, input: input, error: error, meta: meta}
    });
    return dialogRef.afterClosed()
  }

  replace(value: string, params: Array<string>): string {
    params.forEach((element, index) => {
      value = value.replace('$%' + (index + 1), element)
    });
    return value
  }

  goTo(route: string) {
    this.router.navigateByUrl(route)
  }

  goBack() {
    window.history.back()
  }

  /**
   * Returns true if the screen is mobile size
   */
  isMobile() {
    return window.innerWidth <= AppConstants.SCREEN_MOBILE
  }

  //navigate back to previous location
  navigateBack(location) {
    location.back();
  }

  onDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}

