import {Component, Inject} from '@angular/core';
import {AppService} from './commons/app.service';
import {TranslateService} from '@ngx-translate/core';
import {flatMap} from 'rxjs/operators';
import {FourDService} from '4d-mapper';
import {BaseComponent} from './commons/BaseComponent';
import {DOCUMENT} from '@angular/common';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {NavData} from './_nav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ppp';
  constructor( public service:AppService, translate: TranslateService) {
    let codes: string[] = []
    service.languages.forEach(lang => codes.push(lang.code))
    translate.addLangs(codes)
    translate.setDefaultLang('en')

    let browserLang = translate.getBrowserLang()
    //console.log("Browser lang: " + browserLang)
    translate.use(browserLang.match(/en|es|ja/) ? browserLang : 'en')
  }
}
